<template>
	<div class="bg-neurodarkgrey w-screen min-h-screen">
		<div class="p-5"></div>
		<NuxtPage class="z-0"></NuxtPage>
		<div class="p-5"></div>
	</div>
</template>

<script>
export default {
	name: "linktree"
}
</script>

<style>
body {
	font-family: 'Barlow Semi Condensed';
}
</style>
